import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";

export const BlogImage = styled.div`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 240px;
  position: relative;
  overflow: none;
`;

export const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
  width: 100%;
`;

export const BlogSection = styled.div`
  text-align: center;
  padding: 100px 20px 70px;
  h2 {
    margin-bottom: 34px;
  }
  @media only screen and (max-width: 768px) {
    padding: 40px 0px;
  }
`;

export const Blogs = styled.div`
  display: flex;
  margin-bottom: 60px;
  @media only screen and (max-width: 768px) {
    display: block;
    margin-bottom: 45px;
  }
`;

export const Blog = styled.div`
  width: 33.33333333333333%;
  display: flex;
  margin-right: 20px;
  :last-child {
    margin-right: 0px;
  }
  h4 {
    margin-bottom: 18px;
  }
  h4,
  h5 {
    text-align: left;
    color: white;
  }
  a {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

export const BlogText = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 20px 30px 34px;
  flex: 1;
`;
