import React from "react";
import { Link } from "gatsby";
import SEO from "../components/seo";
import Button from "../components/Button/Button";
import BlogBanner from "../components/BlogBanner/BlogBanner";
import Layout from "../components/Layout/Layout";
import Hero from "../components/Hero/Hero";
import styled from "styled-components";
import Img from "gatsby-image";

export const Columns = styled.div`
  display: flex;
  min-height: 570px;
  flex-direction: ${(props) => props.flip && "row-reverse"};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    min-height: 450px;
  }
`;

export const Column = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;

export const TextBlock = styled.div`
  max-width: 400px;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
  }
`;

export const MiddleBlock = styled.div`
  max-width: 460px;
  padding: 20px;
  text-align: ${(props) => (props.right ? "right" : "left")};
  @media only screen and (max-width: 768px) {
    padding: 0 40px;
    max-width: 272px;
    float: ${(props) => (props.right ? "right" : "left")};
  }
  @media only screen and (max-width: 500px) {
    padding: 0px;
    max-width: 272px;
    float: ${(props) => (props.right ? "right" : "left")};
  }
`;

const ProductMiddleBlock = styled(MiddleBlock)`
  @media only screen and (max-width: 768px) {
    text-align: center;
    float: none;
    margin: 0 auto;
    max-width: 350px;
  }
`;

export const MidPage = styled.div`
  overflow-x: hidden;
  padding-top: 76px;
  p {
    font-size: 20px;
    line-height: 1.5;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 15px;
  }
`;

export const Iphone = styled(Img)`
  max-width: 600px;
  width: 100%;
  height: auto;
  @media only screen and (max-width: 768px) {
    max-width: 360px;
    margin: 0 auto;
  }
`;

// export const SideNettingWrapper = styled.div`
//   width: 100%;
//   background-color: red;
//   position: absolute;

//   @media only screen and (min-width: 1440px) {
//     width: 1440px;
//     margin-left: calc(-100vw / 2 + 1440px / 2);
//     margin-right: calc(-100vw / 2 + 1440px / 2);
//   }
// `;

export const SideNetting = styled(Img)`
  max-width: 600px;
  width: 100%;
  height: auto;
  position: absolute !important;
  right: 0;
  transform: translateX(20px);
  z-index: -1;

  @media only screen and (max-width: 1000px) {
    margin-top: 5%;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: -2%;
  }
  @media only screen and (min-width: 1440px) {
    margin-right: calc(50% - 50vw);
  }
  @media only screen and (max-width: 1440px) {
    width: 40%;
  }
  @media only screen and (max-width: 768px) {
    width: 70%;
    max-width: 400px;
    margin-top: 20%;
  }
`;

export const SideNettingLeft = styled(SideNetting)`
  right: initial;
  left: -20px;
  transform: scaleX(-1);
  @media only screen and (min-width: 1440px) {
    margin-right: initial;
    margin-left: calc(50% - 50vw);
  }
`;

export const Astronaut = styled(Img)`
  max-width: 600px;
  width: 100%;
  height: auto;
  @media only screen and (max-width: 768px) {
    max-width: 450px;
    transform: translate(30px, 60px);
    margin: 0 auto;
    float: right;
  }
`;

export const Rocket = styled(Img)`
  max-width: 560px;
  transform: rotate(9deg) translateX(-24px);
  width: 100%;
  height: auto;
  @media only screen and (max-width: 768px) {
    max-width: 450px;
    transform: translate(-40px, 60px);
    margin: 0 auto;
    float: left;
  }
`;

export const Alien = styled(Img)`
  max-width: 530px;
  width: 100%;
  height: auto;
  @media only screen and (max-width: 768px) {
    max-width: 450px;
    transform: translate(40px, 40px);
    margin: 0 auto;
    float: right;
  }
`;

export const Planet = styled(Img)`
  max-width: 490px;
  width: 100%;
  height: auto;

  @media only screen and (max-width: 768px) {
    max-width: 180px;
    margin: 0 auto 10px;
  }
`;

export const ProductBlock = styled.div`
  display: flex;
  margin: 0 auto 24px;
  min-height: 570px;
  background: ${({ pink }) =>
    pink
      ? "linear-gradient(to bottom right, #ff0084 -10%, #ca00ff 50%)"
      : "#0d0645"};
  h4 {
    ${({ pink }) => pink && "color: white;"}
  }
  flex-direction: ${(props) => props.flip && "row-reverse"};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px 20px 40px;
    min-height: auto;
  }
`;

export const ProductColumn = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

const IndexPage = ({ data }) => (
  <Layout>
    <Hero showRectangle showMouse>
      PROGRESSIVE PRODUCTS
      <br />
      FOR AMBITIOUS BRANDS
    </Hero>
    <Columns>
      <Column style={{ padding: "3vw" }}>
        <Iphone fluid={data.technology.childImageSharp.fluid} alt="iphone" />
      </Column>
      <Column>
        <TextBlock>
          <h2>DISRUPTING THE DIGITAL WORLD</h2>
          <p>
            We’re a modern product consultancy for an evolving world. We help
            turn your ideas and problems into industry beating digital
            solutions. Aim higher with Cosmic.
          </p>
          <Link to="/services">
            <Button>SEE HOW WE'RE DIFFERENT</Button>
          </Link>
        </TextBlock>
      </Column>
    </Columns>

    <MidPage>
      <Columns flip>
        <SideNetting
          fluid={data.sideNetting.childImageSharp.fluid}
        ></SideNetting>
        <Column>
          <Astronaut
            fluid={data.astronaut.childImageSharp.fluid}
            alt="astronaut"
          />
        </Column>
        <Column>
          <MiddleBlock style={{ textAlign: "left" }}>
            <h3>INNOVATE</h3>
            <hr />
            <p>
              Aiming higher than the competition? Let us innovate with you.
              <br />
              We are creativity experts who know the bounds of what is possible.
              <br />
              The sky is no longer the limit.
              {/* Your competition will be left on the launchpad. */}
            </p>
          </MiddleBlock>
        </Column>
      </Columns>

      <Columns>
        <SideNettingLeft
          fluid={data.sideNetting.childImageSharp.fluid}
        ></SideNettingLeft>
        <Column>
          <Rocket fluid={data.rocket.childImageSharp.fluid} alt="rocket" />
        </Column>
        <Column>
          <MiddleBlock right>
            <h3>LAUNCH</h3>
            <hr style={{ marginLeft: "auto" }} />
            <p>
              Do you have an idea that needs bringing to life? We’ll get you to
              market. From prototype to product, we can help plan, design and
              build your next game-changing application.
            </p>
          </MiddleBlock>
        </Column>
      </Columns>

      <Columns flip>
        <SideNetting
          fluid={data.sideNetting.childImageSharp.fluid}
        ></SideNetting>
        <Column>
          <Alien fluid={data.alien.childImageSharp.fluid} alt="alien" />
        </Column>
        <Column>
          <MiddleBlock style={{ textAlign: "left" }}>
            <h3>EVOLVE</h3>
            <hr />
            <p>
              Does an existing product need a little lift? We’ll give it some
              sparkle. We'll build on what you have to create something truly
              remarkable. Your competition will be left on the launchpad.
            </p>
          </MiddleBlock>
        </Column>
      </Columns>
    </MidPage>

    <ProductBlock>
      <ProductColumn>
        <Planet fluid={data.ux.childImageSharp.fluid} alt="ux" />
      </ProductColumn>
      <ProductColumn>
        <ProductMiddleBlock>
          <h4>USER EXPERIENCE</h4>
          <h2>PRODUCTS DESIGNED FOR REAL USERS</h2>
          <p>
            Cosmic analyse real users to ensure the products we create have
            effective results. We dig deep into the core purpose of your
            application and outline the most efficient route to its delivery.
          </p>
          <Link to="/services">
            <Button>FIND OUT MORE</Button>
          </Link>
        </ProductMiddleBlock>
      </ProductColumn>
    </ProductBlock>

    <ProductBlock pink flip>
      <ProductColumn>
        <Planet
          fluid={data.creativity.childImageSharp.fluid}
          alt="creativity"
        />
      </ProductColumn>
      <ProductColumn>
        <ProductMiddleBlock>
          <h4>DESIGN AND INNOVATION</h4>
          <h2>CREATIVITY IN EVERYTHING</h2>
          <p>
            Innovation is at the centre of everything we do. We work with highly
            talented and experienced visual designers to ensure your digital
            product is a work of art. We believe that a user will judge your
            product by the way it appears, which is why we ensure it's love at
            first sight.
          </p>
          <Link to="/services">
            <Button transparent>FIND OUT MORE</Button>
          </Link>
        </ProductMiddleBlock>
      </ProductColumn>
    </ProductBlock>

    <ProductBlock>
      <ProductColumn>
        <Planet
          fluid={data.development.childImageSharp.fluid}
          alt="development"
        />
      </ProductColumn>
      <ProductColumn>
        <ProductMiddleBlock>
          <h4>TECHNOLOGY</h4>
          <h2>DEVELOPING GAME CHANGERS</h2>
          <p>
            Our developers have vast experience building software for some of
            the largest and most successful companies in the UK. We pride
            ourselves in creating products that are fast, reliable and secure,
            using the most modern tools and web technologies available.
          </p>
          <Link to="/services">
            <Button>FIND OUT MORE</Button>
          </Link>
        </ProductMiddleBlock>
      </ProductColumn>
    </ProductBlock>

    <BlogBanner></BlogBanner>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    iphone: file(relativePath: { eq: "iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    astronaut: file(relativePath: { eq: "astronaut.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    rocket: file(relativePath: { eq: "rocket.png" }) {
      childImageSharp {
        fluid(maxWidth: 560, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    alien: file(relativePath: { eq: "alien.png" }) {
      childImageSharp {
        fluid(maxWidth: 530, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ux: file(relativePath: { eq: "planets/user-testing.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    creativity: file(relativePath: { eq: "planets/innovation.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    development: file(relativePath: { eq: "planets/development.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    technology: file(relativePath: { eq: "planets/technology.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sideNetting: file(relativePath: { eq: "side-netting.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
