import styled from "styled-components";

export const Button = styled.button`
  font-family: Heebo Medium;
  border: 0;
  padding: 18px 34px;
  color: white;
  background: ${({ transparent }) =>
    transparent
      ? "transparent"
      : "linear-gradient(to right, #ff0084, #ca00ff)"};
  border: ${({ transparent }) =>
    transparent ? "1px solid white" : "1px solid transparent"};
  letter-spacing: 3px;
  cursor: pointer;
  /* transition: all 0.1s ease-in-out; */
  background-position: -2px;
  background-size: calc(100% + 4px);
  :hover {
    /* background: linear-gradient(to right, #0043ff, #0095ff);
   
    */
    /* border: 1px solid white; */
    background: none;
    background-position: -2px;
    background-size: calc(100% + 2px);
    background-color: #c82ffc;
    /* transform: scale(1.02); */
  }
`;
