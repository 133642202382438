import React from "react";
import SEO from "../../components/seo";
import Layout from "../../components/Layout/Layout";
import Hero from "../../components/Hero/Hero";
import { ProductBlock, Columns, Column, TextBlock, Iphone } from "../index";
import styled from "styled-components";
import Img from "gatsby-image";
import Button from "../../components/Button/Button";

const ThirdsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;

  h2 {
    font-size: 46px;
    padding-bottom: 4px;
    @media only screen and (max-width: 768px) {
      font-size: 40px;
    }
  }
  h4 {
    color: white;
    padding-top: 6px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 40px 0;
  }
`;

const Thirds = styled.div`
  display: flex;
  width: 33.333333333333333%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    display: block;
  }
  @media only screen and (max-width: 1000px) {
    flex-direction: row;
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const SVGImage = styled.img`
  width: 100%;
  max-width: 40px;
`;

export const CallPlanet = styled(Img)`
  height: 130px;
  width: 140px;
  transform: scale(1.6);

  @media only screen and (max-width: 1000px) {
    transform: none;
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 220px;
  }
`;

export const ContactImage = styled(Img)`
  max-width: 600px;
  width: 100%;
  height: auto;
  transform: scaleX(-1) translate(-80px, 50px);
  @media only screen and (max-width: 1000px) {
    transform: scaleX(-1) translateY(50px);
  }
`;

export const BlueGradientBlock = styled(ProductBlock)`
  background: linear-gradient(to bottom right, #0d0645 0%, #513ce3 75%);
  min-height: 500px;
  margin-bottom: 100px;
`;

const SmallerColumns = styled(Columns)`
  min-height: 520px;
  margin: 0 auto;
  margin-top: -90px;
  max-width: 1200px;
  margin-bottom: 40px;
`;

const Email = styled.a`
  color: #61bcff;
  font-size: 22px;
  &:hover {
    text-decoration: underline;
  }
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
  @media only screen and (max-width: 1000px) {
    margin-bottom: 0px !important;
    width: 50%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 30px !important;
  }
`;

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <Hero
        smaller
        subtitle="If you have a problem, brief or just a question, why not drop us a message and see how we can help"
      >
        WE'D LOVE
        <br />
        TO HEAR FROM YOU
      </Hero>

      <SmallerColumns>
        <Column style={{ padding: "6.25vw" }}>
          <Iphone fluid={data.iphone.childImageSharp.fluid} alt="iphone" />
        </Column>
        <Column>
          <TextBlock>
            <h2>
              WHY NOT DROP
              <br />
              US A MESSAGE.
            </h2>
            <p>
              Feel free to email, call or contact us in any of the ways
              mentioned below. We're always happy to speak about any ideas or
              problems you may have.
            </p>
            <a href="mailto:hello@wearecosmic.co.uk" target="_blank">
              <Button>hello@wearecosmic.co.uk</Button>
            </a>
          </TextBlock>
        </Column>
      </SmallerColumns>

      <BlueGradientBlock>
        <ThirdsWrapper>
          <Thirds>
            <SocialLink href="tel:0044‪2077709955‬">
              <CallPlanet fluid={data.ux.childImageSharp.fluid} alt="ux" />
            </SocialLink>
            <SocialLink
              href="tel:0044‪2077709955‬"
              style={{ paddingBottom: 0, marginBottom: "-30px" }}
            >
              <h4>CALL US ON</h4>
              <h2>+44‪ (0)20 7770 9955‬‬</h2>
            </SocialLink>
          </Thirds>

          <Thirds>
            <SocialLink
              target="_blank"
              href="https://www.instagram.com/wearecosmicuk"
            >
              <SVGImage src={require("../../images/social/instagram.svg")} />
              <h4>INSTAGRAM</h4>
              <h2>@WEARECOSMICUK</h2>
            </SocialLink>
            <SocialLink
              target="_blank"
              href="https://www.facebook.com/wearecosmicuk"
            >
              <SVGImage src={require("../../images/social/facebook.svg")} />
              <h4>FACEBOOK</h4>
              <h2>/WEARECOSMICUK</h2>
            </SocialLink>
          </Thirds>
          <Thirds>
            <SocialLink
              target="_blank"
              href="https://twitter.com/wearecosmicuk"
            >
              <SVGImage src={require("../../images/social/twitter.svg")} />
              <h4>TWITTER</h4>
              <h2>@WEARECOSMICUK</h2>
            </SocialLink>
            <SocialLink
              target="_blank"
              href="https://www.linkedin.com/company/wearecosmic"
            >
              <SVGImage src={require("../../images/social/linkedin.svg")} />
              <h4>LINKEDIN</h4>
              <h2>/WEARECOSMICUK</h2>
            </SocialLink>
          </Thirds>
        </ThirdsWrapper>
      </BlueGradientBlock>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    iphone: file(relativePath: { eq: "iphone.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ux: file(relativePath: { eq: "planets/user-testing.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    strategy: file(relativePath: { eq: "planets/strategy.png" }) {
      childImageSharp {
        fluid(maxWidth: 490, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    astronaut: file(relativePath: { eq: "astronaut.png" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
