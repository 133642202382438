import React from "react";
import * as S from "./BlogBanner.styles";
import PropTypes from "prop-types";
import Button from "../Button/Button";
import { Link, graphql, StaticQuery } from "gatsby";
import PreviewCompatibleImage from "../PreviewCompatibleImage";

const blogColours = ["#0F0654", "#513CE3", "#C82FFC"];

const BlogBanner = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark;
  const latest3Posts = posts.slice(0, 3);
  return (
    <S.BlogSection>
      <h2>FROM ThE OUTERWORLD</h2>
      <S.Blogs>
        {latest3Posts.map(({ node: post }, index) => (
          <S.Blog>
            <Link to={post.fields.slug} key={post.id}>
              {/* <S.BlogImage
                image={post.frontmatter.featuredimage.childImageSharp.fluid}
              ></S.BlogImage> */}
              {/* <PreviewCompatibleImage
                imageInfo={{
                  image: post.frontmatter.featuredimage,
                  alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                }}
              /> */}
              <S.BlogImage>
                <S.StyledBackgroundImage
                  fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                />
              </S.BlogImage>
              <S.BlogText backgroundColor={blogColours[index]}>
                <h4>{post.frontmatter.tags[0]}</h4>
                <h5>{post.frontmatter.title}</h5>
              </S.BlogText>
            </Link>
          </S.Blog>
        ))}
      </S.Blogs>
      <Link to="/blog">
        <Button>READ MORE</Button>
      </Link>
    </S.BlogSection>
  );
};

BlogBanner.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                tags
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogBanner data={data} count={count} />}
  />
);
